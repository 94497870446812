import React from 'react';
import {
  GALLERY_CONSTS,
  addPresetStyles,
  addLayoutStyles,
  isEligibleForLeanGallery,
} from 'pro-gallery';
import '../styles/dynamic/common/GalleryWrapperWixStyles.scss';
import { experimentsWrapper, window } from '@wix/photography-client-lib';
import { utils } from '../utils/webUtils';
import SiteHelper from './helpers/siteHelper'; // ~35kb
import DimensionsHelper from './helpers/dimensionsHelper'; // ~13kb
import FullscreenHelper from './helpers/fullscreenHelper'; // ~5kb
import LogHelper from './helpers/logHelper'; // ~12kb
import ItemsHelper from './helpers/itemsHelper'; // ~113kb (!)
import ItemActionsHelper from './helpers/itemActionsHelper'; // ~113kb (!)
import AccessibilityHelper from './helpers/accessibilityHelper'; // ~1kb
import ProGalleryTestIdentifier from './ProGalleryTestIdentifier';
import SyncEventHandler from './helpers/syncEventHandler';

const UNKNOWN_CONTAINER = {
  width: 980,
  height: 500,
};

export default class CommonGalleryWrapper extends React.Component {
  constructor(props) {
    props = { ...props.host, ...props }; // untill props.host will be fully active sv_addPropsToHostInNativeComponent
    super(props);
    this.shouldUseBlueprints = () =>
      experimentsWrapper.getExperimentBoolean(
        'specs.pro-gallery.useBlueprints',
      );
    this.isLeanGalleryAllowed = () =>
      experimentsWrapper.getExperimentBoolean(
        'specs.pro-gallery.allowLeanGallery',
      );
    this.shouldUseCustomElement = () =>
      (props.queryParams && props.queryParams.useCustomElement === 'true') ||
      experimentsWrapper.getExperimentBoolean(
        'specs.pro-gallery.useCustomElement',
      );

    this.avoidGallerySelfMeasure = true; // the wrapper is measuring for the gallery
    this.state = {
      fullscreen: {
        clickedIdx: props.clickedIdx >= 0 ? props.clickedIdx : -1,
        fullscreenAnimating: false,
        directFullscreenItem:
          !window.firstProGalleryRenderWithFullscreen &&
          props.directFullscreenItem,
      },
      isAccessible: false,
      itemsLoveData: {},
      container: {
        ...props.dimensions,
        avoidGallerySelfMeasure: this.avoidGallerySelfMeasure,
      },
      bannerHeight: 0,
    };
    // ---------- dont change the order or else (unless you know what you are doing) ------------ //
    const isStoreGallery = this.isStoreGallery();
    this.itemsHelper = new ItemsHelper(this, props, isStoreGallery);
    this.siteHelper = new SiteHelper(this, props, isStoreGallery);
    this.dimensionsHelper = new DimensionsHelper(this, props);
    this.logHelper = new LogHelper(this, props, this.getSentryDSN());
    this.itemActionsHelper = new ItemActionsHelper(this, props, isStoreGallery);
    this.fullscreenHelper = new FullscreenHelper(this, props, isStoreGallery);
    this.accessibilityHelper = new AccessibilityHelper(this, props);
    this.syncEventHandler = new SyncEventHandler(this, props);
    this.onNewProps(props);

    this.generateApiIfNeeded();
    if (props.directFullscreenItem) {
      window.firstProGalleryRenderWithFullscreen = true;
    }
  }

  // return true if art-store and false for pro-gallery
  isStoreGallery() {
    return false;
  }
  // fullscreen wrapper for OOI apps
  getFullscreenWrapperElement() {
    return null;
  }
  // sentry dsn for the app
  getSentryDSN() {
    return '';
  }
  // item resizer - with watermark for art-store
  getItemResizer() {
    return null;
  }
  // props that are passed for fullscreen wrapper
  getArtStoreProps() {
    return {};
  }
  // watermark for pro-gallery (if false no watermark will be send)
  getWatermark() {
    return false;
  }
  // get pro gallery element (artstore adding banner)
  getProGalleryElement(ProGalleryElement) {
    const wrappedProGalleryElement = this.getWrappedProGalleryIfNeeded(
      ProGalleryElement,
    );
    return wrappedProGalleryElement || ProGalleryElement;
  }

  getWrappedProGalleryIfNeeded(ProGalleryElement) {
    return false;
  }

  getFullscreenSelectedIndex() {
    return this.state.fullscreen.directFullscreenItem &&
      this.state.fullscreen.directFullscreenItem.itemId
      ? 0
      : this.state.fullscreen.clickedIdx;
  }

  // end of common methods
  componentDidMount() {
    this.accessibilityHelper.initAccessibility();
    this.dimensionsHelper.setParentDidMount();
    this.dimensionsHelper.createResizeObserver();
    this.dimensionsHelper.createIntersectionObserver();

    this.onNewProps(this.props);
  }

  componentWillReceiveProps(props) {
    this.onNewProps(props);
  }

  componentWillUnmount() {
    this.accessibilityHelper.cleanupAccessibility();
  }

  onNewProps(props) {
    props = { ...props.host, ...props }; // untill props.host will be fully active sv_addPropsToHostInNativeComponent
    this.syncEventHandler.update(props);
    this.dimensionsHelper.update(props);
    this.siteHelper.update(props);
    this.fullscreenHelper.update(props);
    this.logHelper.update(props);
    this.itemsHelper.update(props);
    this.itemActionsHelper.update(props);
    this.accessibilityHelper.update(props);
    !this.isStoreGallery() && this.setNewDirectFullscreenIfNeeded(props);
    this.viewMode = this.siteHelper.parseViewMode(props.viewMode);
    this.formFactor = props.host.style.styleParams.booleans.responsive
      ? undefined
      : this.siteHelper.parseFormFactor(props.formFactor);
  }

  setNewDirectFullscreenIfNeeded(props) {
    if (!props.directFullscreenItem) {
      return;
    }
    if (
      props.directFullscreenItem.itemId &&
      (!this.state.fullscreen.directFullscreenItem ||
        this.state.fullscreen.directFullscreenItem.itemId !==
          props.directFullscreenItem.itemId)
    ) {
      !window.firstProGalleryRenderWithFullscreen &&
        this.setState({
          fullscreen: {
            ...this.state.fullscreen,
            directFullscreenItem: props.directFullscreenItem,
          },
        });
      window.firstProGalleryRenderWithFullscreen = true;
    }
  }

  generateApiIfNeeded() {
    try {
      if (utils.shouldDebug('albums_api') || utils.isInAlbumsBuilder()) {
        console.log('Generating API for Albums in window.AlbumsGalleryApi');
        window.AlbumsGalleryApi = {
          getItems: () => this.itemsHelper.pgItemsProps().items,
          setItems: (items) => {
            this.setState({ manualItems: items });
          },
          getOptions: () => this.siteHelper.getPGStyles(),
          setOptions: (styleParams) => {
            this.setState({ manualStyleParams: styleParams });
          },
        };
        window.addEventListener(
          'message',
          (event) => {
            try {
              console.log('Message arrived', event);
              const { method, data } = event.data;
              window.AlbumsGalleryApi[method](data);
            } catch (err) {
              console.log('Bad message arrived', err, event);
            }
          },
          false,
        );
      }
    } catch (e) {
      console.error('Could not create Manual Api', e);
    }
  }

  canRender(notInView, galleryId, pgStyles) {
    if (
      utils.isSSR() &&
      this.viewMode !== GALLERY_CONSTS.viewMode.SEO &&
      notInView
    ) {
      if (utils.isVerbose()) {
        console.log('PG not in view, skipping');
      }
      // for this case, reportAppLoaded is already called in the viewerScript
      return false;
    }

    if (this.itemsHelper.areOneOrMoreItemsCorrupted(this.itemsProps.items)) {
      console.error('Gallery Wrapper, one or more items are corrupted');
      if (typeof this.props.sentryReport === 'function') {
        const error =
          'Gallery Wrapper, one or more items are corrupted. galleryId = ' +
          galleryId +
          ' items = ' +
          this.itemsProps.items;
        this.props.sentryReport(error);
      }
      this.logHelper.onAppLoaded();
      return false;
    }

    if (
      pgStyles.oneRow &&
      (this.state.container.height === undefined ||
        this.state.container.height <= 0)
    ) {
      // for this case, the height is 0 at the beginning, and will be set later.
      // so the appLoaded will be reported as usual, but in next renders
      return false;
    }

    return true;
  }

  getBlueprint = () => {
    const { blueprint } = this.props;
    if (blueprint) {
      return blueprint;
    } else {
      return null;
    }
  };

  isUnKnownContainer() {
    const {
      container: { width, height },
    } = this.state;
    return typeof width !== 'number' || typeof height !== 'number';
  }

  isPrerenderMode(isLeanGallery) {
    const useCustomElement = this.shouldUseCustomElement();
    const isUnKnownContainer = this.isUnKnownContainer();
    const isSEO = this.viewMode === GALLERY_CONSTS.viewMode.SEO;
    return !isLeanGallery && useCustomElement && isUnKnownContainer && !isSEO;
  }

  getGalleryContainer(isPrerenderMode) {
    const container = { ...this.state.container };
    if (isPrerenderMode) {
      container.width = UNKNOWN_CONTAINER.width;
      container.height = UNKNOWN_CONTAINER.height;
    }
    return container;
  }

  getGalleryViewMode(isPrerenderMode) {
    return isPrerenderMode ? GALLERY_CONSTS.viewMode.PRERENDER : this.viewMode;
  }

  render() {
    let blueprint = {};
    if (this.shouldUseBlueprints()) {
      blueprint = this.getBlueprint();
      if (!blueprint) {
        // TODO - should move to canRender
        console.log('>>>NO BLUEPRINT');
        return null;
      }
    }

    const cssBaseUrl = utils.isDev()
      ? 'https://localhost:3200/'
      : this.props.cssBaseUrl;
    const props = { ...this.props.host, ...this.props }; // until props.host will be fully active sv_addPropsToHostInNativeComponent
    if (this.viewMode === GALLERY_CONSTS.viewMode.PREVIEW) {
      if (!props.propFromSetPropsIndicator) {
        // if this prop do not exist in the component (commonGalleryWrapper), it means that setProps was not called yet
        return null; // if setProps was not called yet, we don't want to render. For now in preview only
      }
    }
    const {
      queryParams,
      notInView,
      id,
      galleryId,
      forceHover,
      pageUrl,
    } = props;
    const useCustomElement = this.shouldUseCustomElement();
    const pgStyles = this.siteHelper.getPGStyles();
    this.itemsProps = this.itemsHelper.pgItemsProps(pgStyles);
    const noItemsToRender =
      !this.itemsProps ||
      !this.itemsProps.items ||
      this.itemsProps.items.length === 0;
    if (
      utils.isSSR() &&
      (noItemsToRender ||
        experimentsWrapper.getExperimentBoolean('specs.pro-gallery.skipSsr') ||
        (queryParams && queryParams.skipPgSsr === 'true'))
    ) {
      console.error('Skipping Pro Gallery SSR!', this.props);
      return (
        <div
          id={`gallery-wrapper-${id}`}
          key={`gallery-wrapper-${id}`}
          style={{ overflow: 'hidden', height: `100%`, width: '100%' }}
        />
      );
    }

    if (utils.isVerbose()) {
      console.log('Pro Gallery wrapper!', this.props);
      console.count('[OOISSR] proGallery ooi wrapper render');
    }

    this.pgProps = {
      domId: id,
      galleryId,
      allowSSR: true,
      container: this.state.container,
      forceHover,
      noFollowForSEO: !this.siteHelper.isPremiumSite(),
      formFactor: this.formFactor,
      viewMode: this.viewMode,
      scrollingElement: this.siteHelper.getScrollingElement(),
      itemsLoveData: this.state.itemsLoveData, // can be removed cause the custom renderers are receiving it already
      resizeMediaUrl: this.getItemResizer(),
      pageUrl,
    };

    if (this.getWatermark()) {
      this.pgProps.watermark = this.getWatermark();
    }

    const GalleryComponent = this.getGalleryComponent();
    let dom = [];
    if (this.canRender(notInView, galleryId, pgStyles)) {
      const staticCssFile = this.isStoreGallery()
        ? 'artStoreStaticCss.min.css'
        : 'staticCss.min.css';
      dom.push(
        <link
          key="static-link"
          rel="stylesheet"
          href={`${cssBaseUrl}${staticCssFile}`}
        />,
      );
      const galleryComponentProps = {
        styles: pgStyles,
        eventsListener: this.syncEventHandler.eventHandler,
        settings: { avoidInlineStyles: !useCustomElement },
        ...this.pgProps,
        ...this.itemsProps,
        ...this.itemsHelper.getExternalInfoRenderers(),
        ...blueprint,
        useBlueprints: this.shouldUseBlueprints(),
      };
      const isLeanGallery =
        this.isLeanGalleryAllowed() &&
        isEligibleForLeanGallery(galleryComponentProps);
      const isPrerenderMode = this.isPrerenderMode(isLeanGallery);
      const container = this.getGalleryContainer(isPrerenderMode);
      const galleryViewMode = this.getGalleryViewMode(isPrerenderMode);
      if (useCustomElement && !isLeanGallery) {
        const customElementProps = {
          'data-parent-id': 'gallery-wrapper-' + id,
        };
        if (utils.isSSR()) {
          Object.assign(customElementProps, {
            'data-items': JSON.stringify(
              this.itemsProps.items.slice(0, 25).map((item) => ({
                id: item.itemId,
                width: item.metaData.width,
                height: item.metaData.height,
              })),
            ),
            'data-options': JSON.stringify(
              addLayoutStyles(addPresetStyles(pgStyles)),
            ),
          });
        }
        dom.push(<wix-pro-gallery {...customElementProps} />);
      }
      dom.push(
        <ProGalleryTestIdentifier
          key="pro-gallery-test-ident"
          testType={this.props.testType}
        />,
        this.getProGalleryElement(
          <GalleryComponent
            key="pro-gallery"
            ref={(node) => (this.node = node)}
            {...galleryComponentProps}
            viewMode={galleryViewMode}
            container={container}
            {...blueprint}
          />,
        ),
      );
      const ProFullscreenWrapper = this.getFullscreenElementIfNeeded();
      ProFullscreenWrapper &&
        dom.push(
          <ProFullscreenWrapper
            {...this.pgProps}
            {...this.getArtStoreProps()}
            {...this.fullscreenHelper.fullscreenItemsProps()}
            key="pro-fullscreen"
            styleParams={this.siteHelper.getFullscreenStyles()}
            scrollTo={this.props.scrollTo}
            fullscreenAnimating={this.state.fullscreen.fullscreenAnimating}
            fullscreenIdx={this.getFullscreenSelectedIndex()}
            eventsListener={this.syncEventHandler.fullscreenEventHandler}
            {...blueprint}
          />,
        );
    } else {
      dom = null;
    }
    const shouldChangeParentHeight = experimentsWrapper.getExperimentBoolean(
      'specs.pro-gallery.loadMoreClickedGalleryHeight',
    );
    const responsiveGallery =
      (pgStyles.oneRow ||
        (!pgStyles.oneRow &&
          pgStyles.enableInfiniteScroll === false &&
          !this.loadMoreClicked)) &&
      !(this.props.host.dimensions.height > 0);
    const dangerouslySetInnerHTMLObject = responsiveGallery
      ? {
          __html: `div.${id} {
        height: 100%;
        width: 100%;
        position: relative;
      }
      div.${id} > div {
        position: absolute;
        top: 0;
        left: 0;
      }`,
        }
      : {
          __html: `div.${id} {
      width: 100%;
      }
      ${
        shouldChangeParentHeight &&
        `#${id}{
          height: auto;
        }`
      }
      `,
        };
    return (
      <div
        id={`gallery-wrapper-${id}`}
        key={`gallery-wrapper-${id}`}
        style={{ overflow: 'hidden', height: `100%`, width: '100%' }}
      >
        <style dangerouslySetInnerHTML={dangerouslySetInnerHTMLObject} />
        {dom}
      </div>
    );
  }
}
